@media only screen and (max-width: 991px) {
    .sidebar-main {
        width: 256px;
    }

    .sidebar-main::after {
        width: 256px;
    }

    .home-content {
        width: calc(100% - 256px);
    }
}

@media only screen and (max-width: 959px) {
    .detailed-post-content {
        width: 100%;
        display: none;
    }

    .fullscreen-mode .detailed-post-content {
        display: flex;
    }

    .detailed-post-sidebar {
        width: 100%;
    }

    .fullscreen-mode .detailed-post-sidebar {
        display: none;
    }

    .post-sidebar-head .prev-post,
    .post-sidebar-head .next-post {
        display: none;
    }

}

@media only screen and (min-width: 768px) {
    .d-flex.mobile-menu {
        display: none !important;
    }
}

@media only screen and (max-width: 767px) {
    .thumbnail-slider-wrap .nav {
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    .detailed-post-content .link-box-top {
        padding: 16px;
        background-color: #fff;
    }

    .media-processing img {
        max-width: 120px;
    }

    .loading-main h1 {
        font-size: 20px;
        margin-top: 16px;
    }

    .loading-main img {
        max-width: 160px;
    }

    .sidebar-main {
        position: fixed;
        left: -296px;
        z-index: 99;
        top: 0;
        bottom: 0;
        background: #fff;
        -webkit-transition: left 0.3s ease-in-out;
        -moz-transition: left 0.3s ease-in-out;
        -o-transition: left 0.3s ease-in-out;
        transition: left 0.3s ease-in-out;
    }

    .sidebar-main::after {
        display: none;
    }

    .mobile-menu-open .sidebar-main {
        left: 0;
        height: 100vh;
    }

    .error-main h1 {
        font-size: 24px;
    }

    .error-main img {
        max-width: 200px;
    }

    .error-main p {
        font-size: 16px;
    }

    .home-btn a {
        font-size: 16px;
    }

    .home-content {
        width: 100%;
        padding: 10px;
    }



    .profile-img {
        width: 32px;
        height: 32px;
    }

    .mobile-menu {
        font-size: 12px;
        line-height: 1.67;
        font-family: var(--Museo500);
        padding: 12px;
        background-color: #f7f8fa;
        border-bottom: 1px solid #d9dfea;
        color: #485163;
        text-transform: capitalize;
    }

    .mobile-menu-open .mobile-menu::after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0.75;
        background-color: #aab7cb;
    }

    .mobile-menu .icon {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 21px;
        margin-right: 8px;
        color: #485163;
    }

    .template-name-head {
        padding: 14px 0;
    }

    .multiple-imgs.multiple-imgs-2 .image-container {
        max-width: 50%;
    }

    .slider-main .slick-prev,
    .slider-main .slick-next {
        width: 32px;
        height: 32px;
        font-size: 12px;
    }

    .post-content h3 {
        font-size: 14px;
        line-height: 1.43;
    }

    .post-content p {
        font-size: 14px;
        line-height: 1.43;
    }

    .profile-description {
        padding: 16px;
    }

    .profile-description p {
        font-size: 14px;
        line-height: 1.43;
    }

    .file-name {
        padding: 8px;
    }

    .file-name a {
        font-size: 14px;
    }

    .file-name p {
        font-size: 14px;
    }

    .detailed-post-main .slider-wrap-main .image-container-inner {
        padding: 0;
        min-height: 100svh;
    }

    .detailed-post-content .link-box-content {
        height: 300px;
    }

    .current-time {
        margin-top: 0;
    }

    .multiple-imgs .play-button {
        margin-top: 0;
    }

    .truncate-two-lines {
        height: 44px;
    }
}

@media only screen and (max-width: 480px) {
    .posts-main .posts {
        border-radius: 0;
        margin: 0 -10px 24px;
        box-shadow: unset;
    }
}